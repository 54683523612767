<template>
	<div class="row">
		<div class="card mb-4">
			<div class="card-header">
				<h5 class="text-center mb-3">Nächste Veranstaltungen</h5>
				<span class="d-block w-40px h-4px bg-success mx-auto rounded-3"></span>
			</div>
			<div class="card-body">
				<div class="d-flex py-3" v-for="event in events" :key="event.id">
					<div class="col ps-3">
						<h6 class="font-w-600 mb-1">
							<span v-if="event.canceled" class="badge bg-danger">Abgesagt</span>
							<router-link :to="{ name: 'Veranstaltung', params: { id: event.id } }" class="text-reset link-effect" v-text="event.post.title" />
						</h6>
						<small v-text="getEventDetails(event)" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
export default {
	created() {
		this.$store.dispatch('loadEvents');
	},
	computed: {
		events() {
			return _.orderBy(this.$store.state.events.filter(q => q.status == "published"), 'date_start', 'asc');
		}
	},
	methods: {
		getEventDetails(event)
		{
			if(event.location)
				return `${moment(event.date_start).format('LL')}, ${event.location.title}`;
			else
				return moment(event.date_start).format('LL');
		},
	}
}
</script>