<template>
	<main>
		<HomeHeader :post="headerpost" />
		<HomeNews :posts="posts" />
	</main>
</template>
<script>
import _ from 'lodash'
import HomeHeader from '@/views/HomeHeader.vue'
import HomeNews from '@/views/HomeNews.vue'
export default {
	'name': 'Home',
	components: {
		HomeHeader,
		HomeNews,
	},
	created() {
		this.$store.dispatch('loadPosts');
	},
	computed: {
		headerpost() {
			return _.orderBy(this.$store.state.posts.filter(q => q.category == "news" || q.category == "review"), 'date_created', 'desc')[0];
		},
		posts() {
			return _.orderBy(this.$store.state.posts.filter(q => q.category == "news" || q.category == "review"), 'date_created', 'desc').slice(0,6);
		}
	},
};
</script>