<template>
	<div class="effect-section bg-no-repeat bg-cover bg-center" :style="getStyle">
			<div class="mask opacity-7 bg-black"></div>
			<div class="container position-relative">
				<div class="row min-vh-85 align-items-center pt-12 pb-8 justify-content-center">
					<div class="col-lg-10 pt-5 text-center">
						<h6 class="mb-2 text-white font-w-500" v-text="getType"></h6>
						<h1 class="display-6 text-white fw-light mb-2">
							<router-link v-if="post && post.id" :to="{ name: 'Beitrag', params: { id: post.id } }" class="text-reset link-effect" v-text="post.title" />
						</h1>
						<div class="text-white-65 mb-4" v-if="post && post.text" v-html="post.text"></div>
						<div class="nav white-link text-white small justify-content-center">
							<span class="mb-2 me-4"><i class="fad fa-clock me-2"></i>{{ getDate }}</span>
							<span class="mb-2 me-4"><i class="fad fa-user me-2"></i>
								<router-link v-if="post && post.creator.id" :to="{ name: 'Mitglied', params: { id: post.creator.id } }" v-text="post.creator.name" class="text-white" />
							</span>
							<span class="mb-2 me-4"><i class="fad fa-tag me-2"></i> {{ getTags }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
import moment from 'moment'
import _ from 'lodash'
export default {
	props: ["post"],
	computed: {
		getStyle()
		{
			return (this.post && this.post.picture) ? `background-image: url(https://api.bs-rt.de/assets/${this.post.picture.id}?key=cover)` : "";
		},
		getType() {
			if(this.post && this.post.category)
			{
				switch(this.post.category)
				{
					case "review":
						return "Rückblick";
					case "news":
						return "Neuigkeiten";
					case "event":
						return "Veranstaltung";
					default:
						return "Beitrag";
				}	
			}
			else
				return null;

		},
		getDate()
		{
			return (this.post && this.post.date_created) ? moment(this.post.date_created).format('LL') : null;
		},
		getTags()
		{
			return (this.post && this.post.tags) ? _.join(this.post.tags,', ') : null;
		}
	},
	methods: {
	}
};
</script>