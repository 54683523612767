<template>
<section class="section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-lg-8">
				<div class="row">
					<div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2" v-for="post in posts" :key="post.id">
						<div class="card shadow-hover link-effect-box hover-box-opacity">
							<div class="position-relative">
								<router-link :to="{ name: 'Beitrag', params: { id: post.id } }">
									<img :src="getImage(post)" class="card-img-top">
								</router-link>
								<div class="position-absolute bg-success py-2 px-3 top-0 start-0 mt-3 ms-3 rounded-3 shadow text-white font-w-500 small hover-box-opacity-in">
									<i class="bi bi-calendar-date me-2"></i> {{ getDate(post) }}
								</div>
							</div>
							<div class="card-body">
								<div class="nav mb-2">
									<a class="text-reset" v-text="getType(post)" />
								</div>
								<h4 class="h5 mb-3"><router-link :to="{ name: 'Beitrag', params: { id: post.id } }" class="text-reset link-effect-in" v-text="post.title" /></h4>
								<p class="m-0"></p>
							</div>
							<div class="card-footer border-top">
								<div class="d-flex align-items-center">
									<i class="fad fa-2x fa-user" style="color: #51cf66;"></i>
									<div class="col ps-3 font-w-500 dark-link">
										<router-link :to="{ name: 'Mitglied', params: { id: post.creator.id } }" v-text="post.creator.name" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-lg-4">
				<HomeSidebarEvents />
			</div>
		</div>
	</div>
</section>
</template>
<script>
import moment from 'moment'
import HomeSidebarEvents from '@/views/HomeSidebarEvents.vue'
export default {
	props: ["posts"],
	components: {
		HomeSidebarEvents
	},
	methods: {
		getImage(post)
		{
			return (post.picture) ? `https://api.bs-rt.de/assets/${post.picture.id}?key=thumbnail-300` : "";
		},
		getAvatar(post)
		{
			return (post.creator.picture) ? `https://api.bs-rt.de/assets/${post.creator.picture.id}?key=thumbnail-150` : "";
		},
		getDate(post)
		{
			return moment(post.date_created).format('LL');
		},
		getType(post) {
			switch(post.category)
			{
				case "review":
					return "Rückblick";
				case "news":
					return "Neuigkeiten";
				case "event":
					return "Veranstaltung";
				default:
					return "Beitrag";
			}
		},
	}
}
</script>